<template>
<div class="email-report">
  <h4>{{$t('generic-str.menu.management')}}</h4>
  <template v-if="fetched">
    <!-- Report table -->
    <div v-if="reports.length" class="show-reports">
      <table class="table table-hover">
        <thead>
          <tr>
            <th scope="col">{{$t('app.type')}}</th>
            <th scope="col">{{$tc('app.canceled', 1)}}</th>
            <th scope="col">{{$t('generic-str.deleted')}}</th>
            <th scope="col">{{$tc('app.delivered', 1)}}</th>
            <th scope="col">Delivery</th>
            <th scope="col">Denylist</th>
            <th scope="col">{{$tc('app.failed', 1)}}</th>
            <th scope="col">{{$t('app.optout')}}</th>
            <th scope="col">{{$t('generic-str.paused-o')}}</th>
            <th scope="col">{{$t('app.queued')}}</th>
            <th scope="col">{{$tc('app.read', 1)}}</th>
            <th scope="col">{{$tc('generic-str.sent-o', 1)}}</th>
            <th scope="col"><b>{{$t('app.total')}}</b></th>
          </tr>
        </thead>
        <tbody>
          <report-item
              v-for="(report, index) in reports"
              :key="index"
              :item="report"
              :index="index" />
        </tbody>
      </table>
    </div>
    <template v-else>
      <LottieAnimNoData />
    </template>
    <!--/Report table -->
    <!-- CANCELED, DELETED, DELIVERED, DELIVERY, DENYLIST, FAILED, OPTOUT, PAUSED QUEUED, READ, SENT, -->
    <!-- <pagination v-model="currentPage" :total-pages="pages" @change="fetch" /> -->
  </template>
  <template v-else>
    <LoadingAnim />
  </template>
</div>
</template>
<script>
import ReportItem from '@/components/rebranding/whatsappj/insights/ReportItem.vue';
import LoadingAnim from '@/components/rebranding/animLottie/Loading.vue';
import LottieAnimNoData from '@/components/rebranding/animLottie/NoData.vue';

import axios from '@/plugins/axios';
import { Object } from '../../../../../public/assets/vendor/lodash/_freeGlobal';

const webApi = axios();

export default {
  name: 'EmailReport',
  components: {
    ReportItem,
    // ReportTotal,
    LoadingAnim,
    LottieAnimNoData,
    // Pagination,
  },
  props: ['dateGte', 'dateLte'],
  computed: {
    account() {
      return this.$store?.state?.account;
    },
  },
  watch: {
    dateGte(newVal, oldVal) {
      console.log('Date updated [dateGte]: ', newVal, ' | was: ', oldVal);
      // this.dateGte = newVal.replace(/\s/, 'T');
    },
    dateLte(newVal, oldVal) {
      console.log('Date updated [dateLte]: ', newVal, ' | was: ', oldVal);
      // this.dateLte = newVal.replace(/\s/, 'T');
      this.fetch();
    },
  },
  data() {
    return {
      currentPage: 1,
      pages: 1,
      fetched: true,
      reports: [],
      status: ['CANCELED', 'DELETED', 'DELIVERED', 'DELIVERY', 'DENYLIST', 'FAILED', 'OPTOUT', 'PAUSED', 'QUEUED', 'READ', 'SENT'],
    };
  },
  methods: {
    fulfillObj() {
      const arrCheck = [];
      const arrCheck2 = [];
      let symDifference = [];
      let symDifference2 = [];
      // Intersection array to verify the values that are missing from the request
      Object.values(this.reports).forEach((val, index) => {
        console.log(val);
        if (index === 0) {
          Object.values(val.detail).forEach((item) => {
            !arrCheck.includes(item.status) ? arrCheck.push(item.status) : '';
          });
        } else {
          Object.values(val.detail).forEach((item) => {
            !arrCheck2.includes(item.status) ? arrCheck2.push(item.status) : '';
          });
        }
      });
      symDifference = this.status.filter((x) => !arrCheck.includes(x)).concat(arrCheck.filter((x) => !this.status.includes(x)));
      symDifference2 = this.status.filter((x) => !arrCheck2.includes(x)).concat(arrCheck2.filter((x) => !this.status.includes(x)));
      console.log('Intersection: ', symDifference, '\n2nd Intersection', symDifference2);
      Object.values(this.reports).forEach((val, index) => {
        /* Object.values(val.detail).forEach((item, i) => {
          console.log(item.status, 'First obj index: ', index, '\nSecond index: ', i);
        }); */
        // Fulfill missing values from the fetch() request into the 'this.reports'
        if (index === 0) {
          symDifference.forEach((obj) => {
            // console.log(`Intersection arr: ${obj}`); // Pushing default data to obj
            this.reports[0].detail.push({
              count: '0',
              origin: this.reports[0].type,
              status: obj,
            });
          });
        } else {
          symDifference2.forEach((obj) => {
            // console.log(`Intersection arr: ${obj}`); // Pushing default data to obj
            this.reports[1].detail.push({
              count: '0',
              origin: this.reports[1].type,
              status: obj,
            });
          });
        }
      });
      /* eslint-disable */
      // Reorder object
      if (this.reports.length) {
        this.reports[0].detail.sort((a,b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0));
        this.reports[1].detail.sort((a,b) => (a.status > b.status) ? 1 : ((b.status > a.status) ? -1 : 0));
      }
      /* eslint-enable */
    },
    updatedProducts() {
      this.fetch();
    },
    fetch() {
      const params = [];
      if (this.dateGte) {
        params.push(`timestamp;gte;'${new Date(this.dateGte).toISOString()}'`);
      }
      if (this.dateLte) {
        params.push(`timestamp;lte;'${new Date(this.dateLte).toISOString()}'`);
      }
      if (this.account) {
        params.push(`whatsapp_business.account_id;eq;'${this.account.id}'`);
      }
      this.fetched = false;
      webApi.get(`/whatsapp/new/insights/report?query[]=${params.join('&query[]=')}`).then(
        (response) => {
          console.log('WhatsAppJvReport response:\n ', response.data);
          this.reports = response.data;
        },
        (error) => {
          this.content = error;
        },
      ).finally(() => {
        this.fulfillObj();
        this.fetched = true;
      });
    },
  },
  mounted() {
    this.fetch();
  },
};
</script>
<style lang="scss" scoped>
.email-report {
  .title {
    margin-right: auto;
    font-weight: bold;
  }
}
</style>
