<!-- eslint-disable -->
<template>
  <div>
    <chart ref="chart" :title="$t('sms.reports.status')" :data="data" :loading="loading"></chart>
  </div>
</template>

<!-- eslint-disable -->
<script>
import moment from 'moment';
import Chart from '@/components/rebranding/Chart.vue';
import axios from '@/plugins/axios';

const webApi = axios();

export default {
  name: 'StatusMessagesChart',
  components: {
    Chart,
  },
  props: {
    startDate: {
      type: String,
    },
    endDate: {
      type: String,
    },
    filter: {
      type: Object,
    },
  },
  data() {
    return {
      loading: true,
      data: [],
      emptyData: [
        {"group_by": "Lido","values": [["",0]]},
        {"group_by": "Entregue","values": [["",0]]},
        {"group_by": "Falha","values": [["",0]]},
        {"group_by": "Optout","values": [["",0]]},
        {"group_by": "Enviada","values": [["",0]]},
    ]
    };
  },
  computed: {
    timeWindow() {
      if (moment(this.endDate).diff(moment(this.startDate), 'days')) {
        return 'day';
      }
      return 'hour';
    },
  },
  methods: {
    fetch(filter) {
      this.loading = true;

      // Get customer account id only if is admin whatsapp report.
      let id;
      if (this.$route.name != 'WhatsAppMetaStatReport') {
        id = (filter.account_id.in && filter.account_id.in.length > 0) ? filter.account_id.in : this.$store.state.account.id;
      } else {
        id = this.$store.state.account.id;
      }
      
      let filterParam = {
        account_id: id,
        start_date: new Date(filter.gte).toISOString(),
        end_date:  new Date(filter.lte).toISOString(),
        time_window: this.timeWindow,
        group_by: 'status',
        order_by: 'timestamp;asc',
        filter: {
          status: [],
        },
      };
      console.log('FILTER PARAM ======>', filterParam);
      if (filter.campaign) {
        filterParam.campaign_ext_id = filter.campaign;
      }

      if (filter.status && filter.status.length > 0) {
        filterParam.filter.status = filter.status.map((value) => value.toUpperCase());;
      } else {
        delete filterParam.filter;
      }

      webApi.post('/whatsapp/new/insights', filterParam).then((response) => {
        this.data = response.data.map((insight) => {
          insight.group_by = this.$tc(`app.${insight.group_by.toLowerCase()}`);
          insight.values = insight.values.map((value) => [value.date, value.count]);
          return insight;
        });
        if (response.data.length === 0) {
          this.data = this.emptyData;
        } 
        console.log('Data chart', this.data);
        this.loading = false;
      }, (error) => {
        this.$toast.show({
          title: `${this.$t('sms.infos.filters.status.lbl-error')} ${error.code}`,
          content: error.message,
          type: 'danger',
        });
        this.loading = false;
      });
    },
    exportData(format) {
      this.$refs.chart.exportData(format);
    },
  },
};
</script>

<style scoped lang="scss">
.chart-opt::v-deep {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.chart-opt span:first-of-type {
  margin-right: auto;
  font-weight: bold;
}
.icon_opt {
  background-color: #f7f7f7;
  padding: 4px;
  border-radius: 5px;
  font-size: 32px;
  max-width: 42px;
  cursor: pointer;
  color: var(--gray-font-color);
  transition: 0.5s;
}
.icon_opt.active {
  color: var(--clr-yup-purple);
}
.icon_opt:hover {
  background-color: #e7e7e7;
}
.darkmode .icon_opt {
  background-color: var(--background-3);
}
</style>
